import { gql } from '@apollo/client';

export const LatestEventQuery = gql`
  query latestEvent {
    latestEvent {
      id
      status
      type
      twilioCallSid
      twilioCallResponse
      gate {
        id
        alias
        order
        capacity
        sessions {
          id
          user {
            id
            email
          }
          startedAt
          endedAt
        }
      }
      parking {
        id
        type
      }
      user {
        email
      }
      updatedAt
      isAknowledgedByUser
      completedQuests {
        id
        createdAt
        quest {
          id
          title
          reward
          description
        }
      }
    }
  }
`;
