import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import { useRouter } from 'next/router';
import { useState } from 'react';
import HyperModal from 'react-hyper-modal';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';
import * as unlockAnimationData from '../../../assets/lottie/unlock.json';
import { LatestEventQuery } from '../../../graphql/events/latestEventQuery';
import { UpdateEventMutation } from '../../../graphql/events/updateEventMutation';
import { CreateRewardCustomMutation } from '../../../graphql/rewards/createRewardCustomMutation';
import { useAuth } from '../../../lib/auth';
import {
  LatestEventType,
  ParkingSession,
  ParkingTypeEnum,
} from '../../../utils/utilTypes';
import {
  checkIfGateIsSingular,
  getActiveSessionFromLatestEvent,
  getLabelsForParkingType,
} from '../../../utils/utilsParking';
import { toastManager } from '../../../utils/utilsToast';
import { AppHero } from '../AppHero';
import { Button } from '../Button';
import { ReportIssueModal } from '../ReportIssueModal';
import { UnlockModalProps } from './props';
import { CreateParkingSessionMutation } from '../../../graphql/sessions/createParkingSessionMutation';
import { GetParkingByIdQuery } from '../../../graphql/parkings/getParkingByIdQuery';
import { UpdateParkingSessionMutation } from '../../../graphql/sessions/updateParkingSessionMutation';

export const UnlockModal = ({ parkingDetail }: UnlockModalProps) => {
  const router = useRouter();
  const { currentUser, getCurrentUser } = useAuth();
  const [isParkingIssueModalShown, setIsParkingIssueModalShown] =
    useState<boolean>(false);
  const { t } = useTranslation();

  const { data: latestEventData } = useQuery(LatestEventQuery);
  const [updateEvent] = useMutation(UpdateEventMutation);
  const [createReward, { loading: isLoadingRewardCreate }] = useMutation(
    CreateRewardCustomMutation
  );
  const [createParkingSession, { loading: isLoadingParkingSessionCreate }] =
    useMutation(CreateParkingSessionMutation, {
      refetchQueries: [GetParkingByIdQuery],
    });

  const [updateParkingSession, { loading: isLoadingParkingSessionUpdate }] =
    useMutation(UpdateParkingSessionMutation, {
      refetchQueries: [GetParkingByIdQuery],
    });

  const acknowledgeParkingEvent = async (): Promise<
    LatestEventType | undefined
  > => {
    try {
      const response = await updateEvent({
        variables: {
          id: latestEventData.latestEvent.id,
          data: {
            isAknowledgedByUser: true,
          },
        },
      });

      await getCurrentUser();

      return response.data.updateEvent as LatestEventType;
    } catch (err: any) {
      toastManager.couldNotAcknowledgeParkingEvent();
    }
  };

  const handleCloseIssueModal = async () => {
    setIsParkingIssueModalShown(false);
    await acknowledgeParkingEvent();
  };

  const handlePostUnlock = async (
    updatedEvent: LatestEventType | undefined
  ) => {
    if (parkingDetail.referencedBy.length > 0) {
      const [nextParking] = parkingDetail.referencedBy;
      await router.push(`/?parkingId=${nextParking.id}`);
      return;
    }

    if (updatedEvent && updatedEvent?.completedQuests.length > 0) {
      const uncollectedQuest = updatedEvent.completedQuests[0];
      await router.push(`/quest-rewards/${uncollectedQuest?.id}`);
      return;

      // // TODO: same as with reward
      // console.log('REDIRECT TO QUEST REWARD: ', uncollectedQuest);
      // if (uncollectedQuest) {
      //   await router.push(`/quest-rewards/${uncollectedQuest?.id}`);
      //   return;
      // }
    }

    // const isParkingCampaignActive = parkingDetail?.campaigns?.find(
    //   (campaign: CampaignType) => {
    //     const now = new Date();
    //     const startDate = new Date(campaign.startsAt);
    //     const endDate = new Date(campaign.endsAt);
    //     return now > startDate && now < endDate;
    //   }
    // );
    // if (isParkingCampaignActive) {
    //   const resp = await createReward({
    //     variables: {
    //       campaignId: isParkingCampaignActive.id,
    //     },
    //   });
    //   if (resp?.data?.createRewardCustom?.id) {
    //     await router.push(`/rewards/${resp?.data?.createRewardCustom.id}`);
    //   }
    // }
  };

  const handleAcknowledgeEvent = async () => {
    try {
      const updatedEvent = await acknowledgeParkingEvent();
      await handlePostUnlock(updatedEvent);
    } catch (err) {
      console.log(err);
    } finally {
      // setHideModal(true);
    }
  };

  const handleStartParkingSessionOnSingularGateClick = async () => {
    await createParkingSession({
      variables: {
        parkingId: latestEventData?.latestEvent?.parking?.id,
        gateId: latestEventData?.latestEvent?.gate?.id,
        userId: currentUser?.id,
        startedAt: new Date(),
      },
    });

    const updatedEvent = await acknowledgeParkingEvent();
    await handlePostUnlock(updatedEvent);
  };

  const handleEndParkingSessionOnSingularGateClick = async (
    ongoingSession: ParkingSession
  ) => {
    await updateParkingSession({
      variables: {
        id: ongoingSession.id,
        data: {
          endedAt: new Date(),
        },
      },
    });

    const updatedEvent = await acknowledgeParkingEvent();
    await handlePostUnlock(updatedEvent);
  };

  const renderAcknowledgeButton = () => {
    return (
      <Button
        className="bg-white flex justify-center items-center"
        loading={isLoadingRewardCreate}
        onClick={handleAcknowledgeEvent}
        type="primary"
      >
        <span className="text-primary">{t('confirm')}</span>
      </Button>
    );
  };

  const renderSingleGateFlowButtons = () => {
    const ongoingSession = getActiveSessionFromLatestEvent(
      latestEventData?.latestEvent
    );

    const labels = getLabelsForParkingType(
      t,
      latestEventData?.latestEvent?.parking?.type as ParkingTypeEnum
    );

    if (ongoingSession) {
      return (
        <div className="flex flex-col gap-4">
          <Button
            type="primary"
            className="bg-white flex justify-center items-center"
            onClick={() =>
              handleEndParkingSessionOnSingularGateClick(ongoingSession)
            }
          >
            <span className="text-primary"> {labels.lock}</span>
          </Button>
          <Button
            className="border-white flex justify-center items-center"
            loading={isLoadingRewardCreate}
            onClick={handleAcknowledgeEvent}
            type="outline"
          >
            <span className="text-white">{t('close')}</span>
          </Button>
        </div>
      );
    }

    return (
      <div className="flex flex-col gap-4">
        <Button
          type="primary"
          className="bg-white flex justify-center items-center"
          onClick={handleStartParkingSessionOnSingularGateClick}
        >
          <span className="text-primary">{labels.unlock}</span>
        </Button>
        <p className="text-gray-dark text-xs text-center">
          {t('parkCaseNote')}
        </p>
        <Button
          className="border-white flex justify-center items-center"
          loading={isLoadingRewardCreate}
          onClick={handleAcknowledgeEvent}
          type="outline"
        >
          <span className="text-white">{t('close')}</span>
        </Button>
      </div>
    );
  };

  if (!latestEventData) {
    return null;
  }

  let canShow = false;

  if (latestEventData?.latestEvent) {
    const le = latestEventData.latestEvent;

    if (le.type === 'manual') {
      if (
        (le.status === 'mqtt' ||
          le.status === 'sim_completed' ||
          le.status === 'internal_testing_purposes') &&
        !le.isAknowledgedByUser
      ) {
        canShow = true;
      }
    }
  }

  const isSingleGateFlow = checkIfGateIsSingular(
    latestEventData?.latestEvent?.gate
  );

  return (
    <HyperModal
      isOpen={canShow}
      requestClose={() => {}}
      closeOnCloseIconClick={false}
      isFullscreen={true}
      renderCloseIcon={() => null}
      closeOnEscClick={false}
    >
      <div className="bg-primary h-full overflow-scroll p-6 flex flex-col gap-4">
        <div>
          <AppHero inverted />
        </div>
        <div className="ml-6 mt-6 flex justify-center">
          <div className="w-24 xxs:w-48 h-24 xxs:h-48">
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: unlockAnimationData,
              }}
            />
          </div>
        </div>
        <div className="text-white text-xl xxs:text-2xl text-center font-bold">
          {t('gatesAreOpenTitle')}
        </div>
        <div
          className="text-white text-center w-2/3 mx-auto text-sm xxs:text-md "
          dangerouslySetInnerHTML={{ __html: t('gatesAreOpenDescription') }}
        ></div>
        <div className="flex flex-col gap-4 flex-1  justify-end">
          {isSingleGateFlow
            ? renderSingleGateFlowButtons()
            : renderAcknowledgeButton()}

          <Button
            className="text-white underline"
            type="link"
            onClick={() => setIsParkingIssueModalShown(true)}
          >
            {t('somethingIsWrong')}
          </Button>
        </div>
      </div>
      <ReportIssueModal
        isOpen={isParkingIssueModalShown}
        onClose={handleCloseIssueModal}
      />
    </HyperModal>
  );
};
